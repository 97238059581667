import React from 'react';
import ItemsCarousel from 'react-items-carousel';
import range from 'lodash/range';
import moment, { max, unix } from 'moment';
import LANGUAGE from './locales/locale'

// IMPORTANT NOTE: All times are currently passed from the DB as UTC --> BUT <-- the editor has entered them as JPT which means we must offset the +9 hours difference).
let timezoneOffsetFromUTC = new Date().getTimezoneOffset() + (parseFloat(document.querySelector('body').getAttribute('data-timezone-minutes'))) // we subtract 120 because of the mins (2 hour) offset requirement (see note above)

const timeFormat = document.querySelector('body').getAttribute('data-locale') === 'de-DE' ? 'HH.mm [Uhr]' : 'h:mma'
const date_time_format = document.querySelector('body').getAttribute('data-locale') === 'de-DE' ? 'DD.MM.YY - HH.mm [Uhr]' : 'DD-MM-YY - h:mma'

if (timezoneOffsetFromUTC < 0) {
  timezoneOffsetFromUTC = Math.abs(timezoneOffsetFromUTC)
} else {
  timezoneOffsetFromUTC = -Math.abs(timezoneOffsetFromUTC)
}

let DISABLE_LINKS = false

export default class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeItemIndex: 0,
      currentTime: moment(),
      currentSession: null
    }

    window.addEventListener('resize', () => {
      // Reset
      let slider = document.querySelector('.slider-container .slider-container-inner')
      slider.style.marginLeft = `0px`
    })
  }
  componentWillMount() {
    this.prep()
  }

  prep() {

    let currentTime = moment()
    let sessionHasChanged = false
    let currentSession = null
    let currentSessionIndex = 0
    let activeItemIndex = 0
    let children = []

    this.props.sessions.forEach((session, i) => {
      let progressPercent = 0

      // Check which events are expired or active.
      session.expired = false
      session.active = false

      if (moment(session.timestampStart * 1000).format('X') <= parseFloat(currentTime.format('X')) && moment(session.timestampEnd * 1000).format('X') > parseFloat(currentTime.format('X'))) {
        session.active = true

        if (activeItemIndex === 0) {
          activeItemIndex = i
        }

        let a = parseFloat(currentTime.format('X'))
        let b = parseFloat(moment(session.timestampEnd).format('x'))
        let c = parseFloat(moment(session.timestampStart).format('x'))

        let a1 = b - c
        let a2 = a1 / 100
        let a3 = b - a
        let a4 = a3 / a2

        progressPercent = 100 - a4

        currentSession = session
        currentSessionIndex = i
      }
      if (parseFloat(moment(session.timestampEnd).utc().format('x')) <= parseFloat(currentTime.format('X'))) {
        session.expired = true
      }
      
      let start_time = moment(session.timestampStart * 1000).utc().format(timeFormat)
      let end_time = moment(session.timestampEnd * 1000).utc().format(timeFormat)
      
      if(window.location.href.includes("https://www.daran-gedacht.de")){
        DISABLE_LINKS = true
      }

      children.push(<a key={i} href={!DISABLE_LINKS ? `${session.permalink}` : "#"}><div className={`item ${session.expired ? `expired` : ``} ${session.active ? `active` : ``}`}>
        <span className={`item-progress-bar`} style={{ width: `${progressPercent}%` }}></span>
        <strong>{ session.title }</strong>
        <p>{ session.copy }</p>
        <div>
          <span>{ start_time } - { end_time }</span>
        </div>
      </div></a>)
    })

    if (currentSession && currentSession !== this.state.currentSession) {
      // Trigger a virtual page view
      if (typeof window.trackVirtualPageView !== 'undefined') {
        window.trackVirtualPageView(currentSession.permalink, currentSession.title)
      }

      sessionHasChanged = true
    }

    this.setState({
      children,
      currentSession
    }, () => {

      if (sessionHasChanged) {
        // Make sure the current active session is visible in the timeline.
        this.navigateToIndex(currentSessionIndex)

        console.log('session to trigger chat with', currentSession)
        window.dispatchEvent(new CustomEvent('session_change', { detail: { channel: currentSession.channel, type: currentSession.type, name: currentSession.title } }));
      }

      setTimeout(() => {
        this.prep()
      }, 500)
    });
  }

  changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

  navigate(ev, direction) {
    ev.preventDefault()

    let slider = document.querySelector('.slider-container .slider-container-inner')
    let items = document.querySelectorAll('.slider-container .slider > a')
    let widthPerItem = parseFloat(document.querySelector('.slider-container .slider > a:first-child').clientWidth)
    let currentMarginLeft = slider.style.marginLeft ? parseFloat(slider.style.marginLeft) : 0
    let newMarginLeft = 0
    let visibleInViewport = 1

    if (window.innerWidth > 640) {
      visibleInViewport = 2
    }
    if (window.innerWidth > 700) {
      visibleInViewport = 3
    } 
    if (window.innerWidth > 980) {
      visibleInViewport = 3
    }

    if (direction === 'previous') { // going backwards

      if (currentMarginLeft < 0) {
        newMarginLeft = currentMarginLeft + widthPerItem
      }

    } else { // going forwards

      if (currentMarginLeft > -Math.abs(widthPerItem * (items.length - visibleInViewport))) {
        newMarginLeft = currentMarginLeft - widthPerItem
      } else {
        newMarginLeft = currentMarginLeft
      }
    }

    slider.style.marginLeft = `${newMarginLeft}px`
  }

  navigateToIndex(index) {
    let slider = document.querySelector('.slider-container .slider-container-inner')
    let items = document.querySelectorAll('.slider-container .slider > a')
    let widthPerItem = parseFloat(document.querySelector('.slider-container .slider > a:first-child').clientWidth)
    let newMarginLeft = -Math.abs(index * widthPerItem)

    slider.style.marginLeft = `${newMarginLeft}px`
  }

  render() {
    const {
      activeItemIndex,
      children,
    } = this.state;

    return (
      <div style={{ position: 'relative' }}>

        <div style={{ padding: '20px 70px 20px' }}>
          <h2 style={{ margin: 0, fontSize: 21 }}>{ this.props.category }</h2>
          <h1>{ this.props.title }</h1>
          <span dangerouslySetInnerHTML={{__html: this.props.copy}}></span>
          <div style={{ position: 'absolute', top: 20, right: 50, fontSize: 14 }}>
            {
              !DISABLE_LINKS ? <span>{ this.props.date }</span> : <span></span>
            }
            { this.props.isLive ? <strong style={{ margin: '0 20px' }}><span style={{ display: 'inline-block', marginRight: 8, width: 11, height: 11, backgroundColor: '#01A39C', borderRadius: '50%' }}></span>Live</strong> : null }
          </div>
        </div>

        {/* FOR DEV - Timetravelling */}
        {/* <div style={{ position: 'absolute', top: 50, right: 10, padding: 20, fontFamily: 'arial', fontSize: 13, background: '#01A39C', borderRadius: 5 }}>
          JP Local Time: 
          <select onChange={ (e) => { console.log('e.currentTarget.value', e.currentTarget.value); this.setState({ currentTime: moment(e.currentTarget.value).utc() }) }} style={{ marginLeft: 10 }}>
            <option>Change current time</option>
            <option value="2020-11-04 09:00 +0000">2020-11-04 09:00</option>
            <option value="2020-11-04 09:25 +0000">2020-11-04 09:25</option>
            <option value="2020-11-04 09:30 +0000">2020-11-04 09:30</option>
            <option value="2020-11-04 09:35 +0000">2020-11-04 09:35</option>
            <option value="2020-11-04 09:38 +0000">2020-11-04 09:38</option>
            <option value="2020-11-04 09:40 +0000">2020-11-04 09:40</option>
            <option value="2020-11-04 09:45 +0000">2020-11-04 09:45</option>
            <option value="2020-11-04 10:05 +0000">2020-11-04 10:05</option>
            <option value="2020-11-04 10:25 +0000">2020-11-04 10:25</option>
            <option value="2020-11-04 10:55 +0000">2020-11-04 10:55</option>
            <option value="2020-11-04 11:25 +0000">2020-11-04 11:25</option>
            <option value="2020-11-04 11:30 +0000">2020-11-04 11:30</option>
            <option value="2020-11-04 12:20 +0000">2020-11-04 12:20</option>
            <option value="2020-11-04 15:00 +0000">2020-11-04 15:00</option>
            <option value="2020-11-04 15:15 +0000">2020-11-04 15:15</option>
            <option value="2020-11-04 15:35 +0000">2020-11-04 15:35</option>
            <option value="2020-11-04 15:55 +0000">2020-11-04 15:55</option>
            <option value="2020-11-04 16:25 +0000">2020-11-04 16:25</option>
            <option value="2020-11-04 16:30 +0000">2020-11-04 16:30</option>
            <option value="2020-11-04 17:00 +0000">2020-11-04 17:00</option>
            <option value="2020-11-04 17:30 +0000">2020-11-04 17:30</option>
            <option value="2020-11-04 17:45 +0000">2020-11-04 17:45</option>
          </select>
        </div> */}

        <div className={`slider-container`}>
          <a href="#" onClick={(ev) => { this.navigate(ev, 'previous') }} className={`slider-nav prev`}>{ LANGUAGE.PREVIOUS }</a>
          <div className={`slider-container-inner`}>
            <div className={`slider`}>
              { children }
            </div>
          </div>
          <a href="#" onClick={(ev) => { this.navigate(ev, 'next') }} className={`slider-nav next`}>{ LANGUAGE.NEXT }</a>
        </div>

      </div>
    )
  }
}