import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const element = document.querySelector('[react-clip="schedule"]')

console.log('schedule element', element)

if (element) {
  ReactDOM.render(
    <App
      sessions={ JSON.parse(document.querySelector('body').getAttribute('data-schedule-sessions')) }
      title={ document.querySelector('body').getAttribute('data-schedule-title') }
      copy={ document.querySelector('body').getAttribute('data-schedule-copy') }
      category={ document.querySelector('body').getAttribute('data-schedule-category') }
      date={ document.querySelector('body').getAttribute('data-schedule-date') }
      isLive={ document.querySelector('body').getAttribute('data-schedule-is-live') }
    />,
    element
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
